import React, { useMemo } from "react";
import { DualAxes } from "@ant-design/plots";

const BaseDualAxes = (props) => {
  const {
    columnData,
    lineData,
    xField,
    yField,
    columnSeriesField,
    lineSeriesField,
    columnSeriesFieldNum = 1,
    lineSeriesFieldNum = 1,
  } = props;

  // 根据 columnSeriesFieldNum/lineSeriesFieldNum 过滤data
  const newColumnData = useMemo(() => {
    const filterFields = [
      ...new Set(columnData.map((item) => item[columnSeriesField])),
    ].slice(0, columnSeriesFieldNum);
    const newData = columnData.filter((item) =>
      filterFields.includes(item[columnSeriesField])
    );
    return newData;
  }, [columnData, columnSeriesField, columnSeriesFieldNum]);
  const newLineData = useMemo(() => {
    const filterFields = [
      ...new Set(lineData.map((item) => item[lineSeriesField])),
    ].slice(0, lineSeriesFieldNum);
    const newData = lineData.filter((item) =>
      filterFields.includes(item[lineSeriesField])
    );
    return newData;
  }, [lineData, lineSeriesField, lineSeriesFieldNum]);

  const borderRadius = columnSeriesFieldNum > 1 ? 5 : 10;

  const config = useMemo(
    () => ({
      data: [newColumnData, newLineData],
      xField,
      yField,
      geometryOptions: [
        {
          geometry: "column",
          isGroup: true,
          seriesField: columnSeriesField,
          columnWidthRatio: 0.4,
          columnStyle: {
            radius: [borderRadius, borderRadius, 0, 0], // 设置柱形顶部的圆角效果
          },
        },
        {
          geometry: "line",
          seriesField: lineSeriesField,
          smooth: true,
          lineStyle: {
            smooth: true,
          },
          legend: {
            marker: {
              symbol: (x, y, r) => [
                ["M", x - r, y],
                ["L", x + r, y],
              ], // 设置图例标记为水平线
            },
          },
        },
      ],
    }),
    [
      borderRadius,
      columnSeriesField,
      lineSeriesField,
      newColumnData,
      newLineData,
      xField,
      yField,
    ]
  );
  return (
    <div className="h-full text-left">
      <DualAxes {...config} />
    </div>
  );
};

export default BaseDualAxes;
