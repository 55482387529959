import { Empty } from 'antd';
import PropTypes from 'prop-types';
import useConfig from './config'
import wrapper from 'src/modules/wrapper';
import './style.scss';

const AiText = (props) => {
    const { flow, width, height, content } = props;
    let { data } = useConfig(flow.data, flow.error, flow.isLocal);

    let _jsx = (content || data || "").replace(/\\\"/g, '"');
    const strs = _jsx.split('```html')
    if (strs[1]) {
        _jsx = strs[1].split('```')[0];
    } else {
        _jsx = strs[0];
    }
    _jsx = _jsx.replace(/\\n/g, "");
    if (_jsx[0] !== '<') {
        _jsx = _jsx.slice(1);
        _jsx = _jsx.slice(0, -1);
    }
    console.log(_jsx)

    return (
        <div className="text-container" style={{
            textAlign: 'center'
        }}>
            {
                _jsx ? (
                    <div dangerouslySetInnerHTML={{ __html: _jsx }} />
                ) : (
                    <Empty />
                )
            }
        </div>
    )
}

AiText.propTypes = {
    flow: PropTypes.object.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
}

export default wrapper(AiText);