// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wemo-card-layout {
    padding: 16px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 10px #d9d9d9;
    color: #4D5A73;

    .wemo-card-content {
        width: 100%;
        height: 100%;
    }

    .wemo-card-title {
        margin-bottom: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;

        .wemo-card-title-placeholder {
            flex: 1;
        }

        .wemo-card-title-label {
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
            height: 22px;
            line-height: 22px;
        }

        .wemo-card-title-setting {
            width: 12px;
            justify-self: flex-end;
            cursor: pointer;
        }

        .wemo-card-title-open {
            width: 12px;
            justify-self: flex-end;
            cursor: pointer;
            margin-right: 12px;
        }
    }

    .wemo-card-illustrate {
        height: 22px;
        line-height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/modules/Layout/style.scss"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,4BAA4B;IAC5B,cAAc;;IAEd;QACI,WAAW;QACX,YAAY;IAChB;;IAEA;QACI,mBAAmB;QACnB,gBAAgB;QAChB,aAAa;QACb,mBAAmB;;QAEnB;YACI,OAAO;QACX;;QAEA;YACI,iBAAiB;YACjB,0BAA0B;YAC1B,eAAe;YACf,YAAY;YACZ,iBAAiB;QACrB;;QAEA;YACI,WAAW;YACX,sBAAsB;YACtB,eAAe;QACnB;;QAEA;YACI,WAAW;YACX,sBAAsB;YACtB,eAAe;YACf,kBAAkB;QACtB;IACJ;;IAEA;QACI,YAAY;QACZ,iBAAiB;QACjB,aAAa;QACb,uBAAuB;QACvB,mBAAmB;QACnB,mBAAmB;IACvB;AACJ","sourcesContent":[".wemo-card-layout {\n    padding: 16px;\n    border-radius: 10px;\n    background-color: white;\n    box-shadow: 0 0 10px #d9d9d9;\n    color: #4D5A73;\n\n    .wemo-card-content {\n        width: 100%;\n        height: 100%;\n    }\n\n    .wemo-card-title {\n        margin-bottom: 10px;\n        overflow: hidden;\n        display: flex;\n        align-items: center;\n\n        .wemo-card-title-placeholder {\n            flex: 1;\n        }\n\n        .wemo-card-title-label {\n            font-weight: bold;\n            text-decoration: underline;\n            cursor: pointer;\n            height: 22px;\n            line-height: 22px;\n        }\n\n        .wemo-card-title-setting {\n            width: 12px;\n            justify-self: flex-end;\n            cursor: pointer;\n        }\n\n        .wemo-card-title-open {\n            width: 12px;\n            justify-self: flex-end;\n            cursor: pointer;\n            margin-right: 12px;\n        }\n    }\n\n    .wemo-card-illustrate {\n        height: 22px;\n        line-height: 22px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        margin-bottom: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
