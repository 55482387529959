import { useState, useEffect } from 'react';
import { getDataFromPrevNode } from 'src/utils/get-params';

const useConfig = (data = {}, error, isLocal) => {
    const [config, setConfig] = useState(0);
    const [fontSize, setFontSize] = useState(16);
    const [fontWeight, setFontWeight] = useState(500);

    useEffect(() => {
        if (!error && data) {
            const dataForm = data.dataForm;
            const text = isLocal ? data.data : getDataFromPrevNode(data);
            setConfig(text || "No content found");
            setFontSize(dataForm.fontSize || 16);
            setFontWeight(dataForm.fontWeight || 500);
        }
    }, [data, error])

    return {
        data: config,
        fontSize,
        fontWeight,
    };
}

export default useConfig;