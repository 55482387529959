import React, { useState, useEffect } from "react";
import { Spin } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// import cx from 'classnames';
import Error from 'src/modules/Error';
import wrapper from "src/modules/wrapper";
import "./style.scss";
import useConfig from "./config";
import useAI from 'src/hooks/useAI';
import request, { API_KEY } from 'src/adapters/fixed';
import voiceIcon from 'src/assets/answer.gif';

dayjs.extend(utc);

const promptGen = (data, aiAgentName) => `
你是一个${aiAgentName}的分析师，以下数据是行业中其他企业的近期10条新闻:
${JSON.stringify(data)}
请用专业的语言，先用一段文字总结你获得的数据，再分析这些数据对${aiAgentName}的潜在影响。
请用英文回答我
`

function News({ flow, width, height, company, aiAgentName }) {
  const config = useConfig(flow.data, flow.error, flow.isLocal);
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const ai = useAI()

  useEffect(() => {
    async function _fetchData() {
      setLoading(true)
      const result = await request.get('/v3/stock_news', {
        page: 0,
        tickers: company.symbol,
        apikey: API_KEY
      })
      setLoading(false)
      setData({
        list: result.data.slice(0, 10),
        error: !result.data.length
      })
    }
    _fetchData()
  }, [])

  useEffect(() => {
    if (data.list && data.list.length) {
      ai.chat(promptGen(data.list.map(item => item.title), aiAgentName))
    }
  }, [data.list])

  useEffect(() => {
    if (data.list && data.list.length && refresh) {
      setRefresh(false)
      ai.chat(promptGen(data.list.map(item => item.title), aiAgentName))
    }
  }, [data.list, refresh])

  const dateDesc = (date) => {
    const time = dayjs(date)
    const diffHours = dayjs().utc().diff(time, 'hours')
    // const diffDays = dayjs().diff(time, 'days')
    // console.log(date, dayjs().utc().format())
    if (diffHours < 1) {
      return 'just released'
    }
    if (diffHours < 24) {
      return `${diffHours} hours ago`
    }
    return time.format('YYYY-MM-DD')
  }

  return (
    <div className="news-wrapper">
      <div className="news-header">
        <img
          src={voiceIcon}
          alt=""
          className="icon"
        />
        <span className="title">
          AI News Analysis
        </span>
      </div>
      <Spin
        delay={1000}
        wrapperClassName="competitive-analysis-list-box"
        tip="AI is summarizing..."
        spinning={ai.loading}
      >
        {ai.error ?
          <div className="ai-content-refresh" onClick={() => setRefresh(true)}>
            Something went wrong, please try again...
          </div> :
          <div className="ai-content">
            {ai.answer}
          </div>}
      </Spin>
      <div className="line-box">
        <span className="text">Citation</span>
        <span className="line"></span>
      </div>
      <Spin delay={1000} wrapperClassName="news-list-box" tip="loading..." spinning={loading}>
        {data.error ? <Error /> :
          <div className="news-list-box">
            {(data?.list || []).map((news, key) =>
              <div key={key} className="news-box">
                <div className="news-box-header">
                  <span className="text">{news.site}</span>
                  {/* <span className={cx("text", news.sentiment.toLocaleLowerCase())}>{news.sentiment}</span> */}
                </div>
                <div className="news-box-content">
                  <a href={news.url} target="_blank" title={news.title}
                    className="text title" rel="noreferrer">{news.title}</a>
                  <span className="text time">{dateDesc(news.publishedDate)}</span>
                </div>
              </div>
            )}
          </div>}
      </Spin>
    </div>

  );
}

export default wrapper(News);
