export const fonts = [
    'Courier New',
    'Courier',
    'monospace',
    'Franklin Gothic Medium',
    'Arial Narrow',
    'Arial',
    'Gill Sans',
    'Gill Sans MT',
    'Calibri',
    'Trebuchet MS',
    'Lucida Sans',
    'Lucida Sans Regular',
    'Lucida Grande',
    'Lucida Sans Unicode',
    'Geneva',
    'Segoe UI',
    'Verdana',
    'Times New Roman',
    'Trebuchet MS',
    'Lucida Sans Unicode',
    'Lucida Grande',
    'Lucida Sans',
    'Helvetica',
    'Cambria',
    'Cochin',
    'Georgia',
    'Times',
    'serif',
    'Impact',
    'Haettenschweiler',
    'Arial Narrow Bold',
    'Tahoma',
    'sans - serif',
]