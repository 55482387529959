import PropTypes from 'prop-types';
import useConfig from './config'
import wrapper from 'src/modules/wrapper';
import './style.scss';

const Price = ({ flow, width, height }) => {
    const config = useConfig(flow.data, flow.error, flow.isLocal);

    if (flow.error) return flow.error;

    return (
        <div className="price-container">
            <span className="price-unit">{config.unit}</span>
            <span className="price-data">{config.data}</span>
        </div>
    )
}

Price.propTypes = {
    flow: PropTypes.object.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
}

export default wrapper(Price);