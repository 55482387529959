import React from "react";

function ImageTextContent(props) {
  const {
    title,
    secondaryTitle,
    titleBg,
    centerTitle = false,
    itemMap = [],
    itemNum = 3,
  } = props;

  return (
    <div className="text-left">
      {!!title && (
        <div
          className="aspect-[16/9] relative rounded-[16px] text-white flex items-center justify-center bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${titleBg})`,
          }}
        >
          {centerTitle ? (
            <div className="w-[262px] text-center">
              <div className="mb-[8px] text-title font-bold line-clamp-2">
                {title}
              </div>
              {!!secondaryTitle && (
                <div className="text-base line-clamp-2">{secondaryTitle}</div>
              )}
            </div>
          ) : (
            <div
              className="absolute w-full px-[21px] py-[24px] bottom-0 text-title font-bold truncate"
              style={{
                background:
                  "linear-gradient(0deg, #FFF 0%, rgba(116, 115, 115, 0.25) 0.01%, rgba(0, 0, 0, 0.00) 100%)",
              }}
            >
              {title}
            </div>
          )}
        </div>
      )}

      <ul className="mb-0 p-[24px] flex flex-col gap-[16px]">
        {(itemMap ?? [])
          .slice(0, itemNum)
          .map(({ itemTitle, itemContent, itemImg }) => (
            <li className="flex">
              <div className="flex-1">
                <div className="mb-[8px] text-title font-bold line-clamp-2">
                  {itemTitle}
                </div>
                <div className="text-base line-clamp-2">{itemContent}</div>
              </div>
              {!!itemImg && (
                <div
                  className="ml-[30px] w-[107px] h-[107px] bg-cover bg-center bg-no-repeat"
                  style={{ backgroundImage: `url(${itemImg})` }}
                />
              )}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default ImageTextContent;
