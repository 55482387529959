import { useState, useEffect } from "react";
import { mockColumnData, mockLineData } from "../mockData";
// import getParams from "src/utils/get-params";

const useConfig = (data, error) => {
  const [config, setConfig] = useState({
    columnData: [],
    lineData: [],
    xField: "x",
    yField: "y",
    columnSeriesField: "",
    lineSeriesField: "",
  });

  useEffect(() => {
    if (!error && data) {
      //   const field = data.data;
      //   const meta = data.meta;
      //   const newData = getParams(field, meta);
      const {
        xField = "time",
        yField = ["value", "value"],
        columnSeriesField = "type",
        lineSeriesField = "category",
      } = data.dataForm;
      setConfig({
        columnData: mockColumnData,
        lineData: mockLineData,
        xField,
        yField,
        columnSeriesField,
        lineSeriesField,
      });
    }
  }, [data, error]);

  return config;
};

export default useConfig;
