import "./index.scss"

export default function index(props) {
  return (
    <div className="tabs-box">
        <div className={props.tab === 1 ? 'tabs-item tabs-item-select':'tabs-item'} onClick={() => props.setTab(1)}>月度</div>
        <div className={props.tab === 2 ? 'tabs-item tabs-item-select':'tabs-item'} onClick={() => props.setTab(2)}>季度</div>
        <div className={props.tab === 3 ? 'tabs-item tabs-item-select':'tabs-item'} onClick={() => props.setTab(3)}>回报率</div>
    </div>
  )
}
