import PropTypes from "prop-types";
import { Empty } from "antd";
import useConfig from "./config";
import wrapper from "src/modules/wrapper";
import BaseLineChart from "../BaseLine";

const LineCHart = ({ flow, width, height }) => {
  const config = useConfig(flow.data, flow.error, flow.isLocal);

  return (
    <>
      {config.data.length ? (
        <BaseLineChart {...config} seriesFieldNum={2} />
      ) : (
        <Empty />
      )}
    </>
  );
};

LineCHart.propTypes = {
  flow: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default wrapper(LineCHart);
