import { useState } from 'react'
import request from 'src/adapters/flow';
import Cache from 'src/modules/Cache';

/**
 * @param {string} ResultId - ResultId
 * @param {string} TableId - TableId by flow runed
 * @param {string} NodeId - id of node
 *
 * @returns {object} hooks return
 */
const useFlow = (_data) => {
    const [data, setData] = useState(_data);
    const [error, setError] = useState(null);

    const getData = (newData) => {
        setData(newData);
    }

    const setCache = () => {
        setData(_data)
    }

    return {
        data, // 数据
        error, // error
        setCache,
        getData,
        isLocal: true,
    }
}

export default useFlow;