import React, { useState } from "react";
import wrapper from "src/modules/wrapper";
import useConfig from "./config";
import Tabs from "../ColumnChart/Tabs";
import "./style.scss";

function Avatar({ flow, width, height }) {
  const config = useConfig(flow.data, flow.error, flow.isLocal);
  const [tab, setTab] = useState(1);

  return (
    <>
      <Tabs tab={tab} setTab={setTab}></Tabs>
      <div className="avatar-list">
        {config.data.slice(0, config.num).map((i) => (
          <div className="avatar-item">
            <img className="avatar-img" src={i.url} alt={i.name} />
            <div className="avatar-text">
              <div className="avatar-name">{i.name}</div>
              <div className="avatar-desc">{i.desc}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default wrapper(Avatar);
