import { useState, useEffect } from 'react';

const useConfig = (data, error, isLocal) => {
    const [config, setConfig] = useState(null);

    useEffect(() => {
        if (!error && data && data.dataForm) {
            const { url } = data.dataForm;
            setConfig(url)
        }
    }, [data, error])

    return {
        data: config,
    };
}

export default useConfig;