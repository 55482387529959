import { Empty } from 'antd';
import PropTypes from 'prop-types';
import useConfig from './config'
import wrapper from 'src/modules/wrapper';
import './style.scss';

const Text = (props) => {
    const { flow, width, height, content } = props;
    let { data, fontSize, fontWeight } = useConfig(flow.data, flow.error, flow.isLocal);

    let color;
    if (props.fontSize) fontSize = props.fontSize;
    if (props.fontWeight) fontWeight = props.fontWeight;
    if (props.color) color = props.color;

    const fontStyle = {
        fontSize,
        fontWeight,
        lineHeight: props.lineHeight || '',
    }

    if (props.center) fontStyle.textAlign = 'center';

    return (
        <div className="text-container" style={{
            color,
        }}>
            {
                content ? (
                    <span style={fontStyle} className="text-data">{content}</span>
                ) : data ? (
                    <span style={fontStyle} className="text-data">{data}</span>
                ) : (
                    <Empty />
                )
            }
        </div>
    )
}

Text.propTypes = {
    flow: PropTypes.object.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
}

export default wrapper(Text);