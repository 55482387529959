import { memo, useMemo, useState, useEffect } from 'react';
import cx from 'classnames';
import { Empty } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import './style.scss';
import { Area } from '@ant-design/charts';
import { ReactComponent as ArrowSvg } from 'src/assets/arrow.svg';
import request, { API_KEY } from 'src/adapters/fixed';
import { formatNumber, formatPercent } from 'src/utils/tools';

dayjs.extend(utc);
const today = dayjs().utc().format('YYYY-MM-DD');
const PeriodArray = ['1D', '5D', '1M', '6M'];
const PeriodMapParams = {
  '1D': {
    timeframe: '1min',
    from: dayjs().utc().subtract(1, 'day').format('YYYY-MM-DD'),
    format: 'HH:mm',
  },
  '5D': {
    timeframe: '30min',
    from: dayjs().utc().subtract(5, 'day').format('YYYY-MM-DD'),
    format: 'MM-DD',
  },
  '1M': {
    timeframe: '4hour',
    from: dayjs().utc().subtract(1, 'month').format('YYYY-MM-DD'),
    format: 'MM-DD',
  },
  '6M': {
    timeframe: '4hour',
    from: dayjs().utc().subtract(6, 'month').format('YYYY-MM-DD'),
    format: 'YYYY-MM-DD',
  },
  ytd: {
    timeframe: '4hour',
    from: dayjs().utc().startOf('year').format('YYYY-MM-DD'),
    format: 'YYYY-MM-DD',
  },
  '1Y': {
    timeframe: '4hour',
    from: dayjs().utc().subtract(1, 'year').format('YYYY-MM-DD'),
    format: 'YYYY-MM-DD',
  },
  '5Y': {
    timeframe: '4hour',
    from: dayjs().utc().subtract(5, 'year').format('YYYY-MM-DD'),
    format: 'YYYY-MM-DD',
  },
  max: {
    timeframe: '4hour',
    from: dayjs().utc().subtract(5, 'year').format('YYYY-MM-DD'),
    format: 'YYYY-MM-DD',
  },
};

const AreaComponent = ({ chartConfig }) => {
  return <Area {...chartConfig} />;
};
AreaComponent.displayName = 'MemoArea';
const MemoArea = memo(AreaComponent);

const StockPanel = ({ company, stockInfo, realLoading }) => {
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [periodMapParams, setPeriodMapParams] = useState(PeriodMapParams);
  const [period, setPeriod] = useState('1D');

  async function _fetchChartData() {
    setLoading(true);
    const result = await request.get(
      `/v3/historical-chart/${periodMapParams[period].timeframe}/${company.symbol}`,
      {
        from: periodMapParams[period].from,
        to: today,
        apikey: API_KEY,
      }
    );
    setLoading(false);
    setChartData(result.data.reverse());
  }
  useEffect(() => {
    _fetchChartData();
  }, [period]);
  const chartConfig = useMemo(() => {
    return {
      data: chartData,
      xField: 'date',
      yField: 'open',
      height: 217,
      locale: 'en-US',
      appendPadding: [12, 20, 0, 0],
      tooltip: {
        crosshairs: {
          line: {
            style: {
              stroke: '#A8B2C5',
              lineWidth: 1,
              lineDash: [4, 5],
              cursor: 'pointer',
            },
          },
        },
        marker: {
          symbol: 'circle',
          lineWidth: 0,
          r: 6,
        },
        customContent: (title, data) => {
          const current = data?.[0]?.data || {};
          return (
            <div className="monitoringTip">
              <div className="title">USD ${current?.open}</div>
              <div className="date">
                {dayjs(current?.date).format('YYYY-MM-DD HH:mm:ss')}
              </div>
              <div className="value">
                Transaction volume: {formatNumber(current?.volume || '', 0)}
              </div>
            </div>
          );
        },
        domStyles: {
          monitoringTip: {
            padding: '12px',
          },
          title: {
            textAlign: 'left',
            color: '#2C3444',
            marginBottom: '8px',
          },
          date: {
            textAlign: 'left',
            color: '#A8B2C5',
            marginBottom: '8px',
          },
          value: {
            textAlign: 'left',
            color: '#A8B2C5',
          },
        },
      },
      yAxis: {
        startOnZero: false,
        // min: chartData?.[0] && Math.floor(chartData?.[0].open / 100) * 100,
        min: chartData?.[0] && Math.ceil(Math.min.apply(Math, chartData.map(function (obj) { return obj.open })) - (Math.abs(Math.max.apply(Math, chartData.map(function (obj) { return obj.open }))) - Math.abs(Math.min.apply(Math, chartData.map(function (obj) { return obj.open })))))
      },
      xAxis: {
        label: {
          offsetY: 10,
          formatter: (datum) => {
            return dayjs(datum).format(periodMapParams[period].format);
          },
          style: {
            fill: '#4D5A73',
            cursor: 'pointer',
          },
        },
        tickCount: 4,
        tickLine: {
          length: 5,
          style: {
            lineWidth: 2,
            stroke: 'black',
          },
        },
      },
      color: stockInfo.change >= 0 ? '#AF3636' : '#36AF58',
      areaStyle: () => {
        return {
          // fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
          fill:
            stockInfo.change >= 0
              ? 'l(270) 0:#ffffff 1:#AF3636'
              : 'l(270) 0:#ffffff 1:#36AF58',
        };
      },
    };
  }, [chartData]);
  return (
    <div className="stock-wrapper">
      <div className={cx('price-info', realLoading && 'gray')}>
        <span className="price">${stockInfo.price}</span>
        <span className={cx('trend', stockInfo.change >= 0 ? 'up' : 'down')}>
          <ArrowSvg />
          {stockInfo.changesPercentage.toFixed(2)}%
        </span>
        <span
          className={cx('day-trend', stockInfo.change >= 0 ? 'up' : 'down')}
        >
          {stockInfo.change} today
        </span>
      </div>
      <div className={cx('before-price', realLoading && 'gray')}>
        Before the opening：<em>${stockInfo.previousClose}</em>
      </div>
      {/* <div className="market">
        Market closed: January 5 , UTC-5 04:27:37 · USD · NASDAQ
      </div> */}

      <div className="switch-box">
        {PeriodArray.map((it) => (
          <span
            key={it}
            className={cx('switch-item', period === it && 'active')}
            onClick={() => {
              if (it === 'ytd') {
                const daysDiff = dayjs()
                  .utc()
                  .diff(dayjs().utc().startOf('year'), 'days');
                let timeframe = '1min';
                let format = 'HH:mm';
                if (daysDiff >= 5) {
                  timeframe = '30min';
                  format = 'MM-DD';
                } else {
                  timeframe = '4hour';
                  format = 'YYYY-MM-DD';
                }
                setPeriodMapParams({
                  ...PeriodMapParams,
                  ytd: {
                    timeframe,
                    from: dayjs().utc().startOf('year').format('YYYY-MM-DD'),
                    format,
                  },
                });
              }
              setPeriod(it);
            }}
          >
            {it}
          </span>
        ))}
      </div>
      <MemoArea chartConfig={chartConfig} />
      <div className="summary-box">
        <div className="title">Summary</div>
        <div className="half-item">
          <span>YESTERDAY'S CLOSING PRICE</span>
          <span className="value">${stockInfo.previousClose}</span>
        </div>
        <div className="half-item">
          <span>AVERAGE TRADING VOLUME</span>
          <span className="value">
            {formatNumber(stockInfo.avgVolume || 0, 3)}
          </span>
        </div>
        <div className="half-item">
          <span>TODAY'S PRICE RANGE</span>
          <span className="value">
            ${stockInfo.dayLow}-${stockInfo.dayHigh}
          </span>
        </div>
        <div className="half-item">
          <span>P/E RATIO</span>
          <span className="value">{stockInfo.pe}</span>
        </div>
        <div className="half-item">
          <span>MARKET VALUE</span>
          <span className="value">
            {formatNumber(stockInfo.marketCap || 0, 3)}USD
          </span>
        </div>
        <div className="half-item">
          <span>MAJOR EXCHANGES</span>
          <span className="value">{stockInfo.exchange}</span>
        </div>
      </div>
    </div>
  );
};

export default StockPanel;
