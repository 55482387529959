import { useState, useEffect } from 'react';
import { getDataFromPrevNode } from 'src/utils/get-params';

const useConfig = (data, error, isLocal) => {
    const [config, setConfig] = useState({
        data: [],
        xField: 'x',
        yField: 'y',
        point: {
            size: 5,
            shape: 'diamond',
        },
    });

    useEffect(() => {
        if (!error && data) {
            const { xField, yField } = data.dataForm;
            setConfig({
                autoFit: true,
                data: isLocal ? data.data : (getDataFromPrevNode(data) || []),
                xField: xField || "",
                yField: yField || "",
                point: {
                    size: 5,
                    shape: 'diamond',
                },
            })
        }
    }, [data, error])

    return {
        data: config,
    };
}

export default useConfig;