// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cash-wrapper,
.balance-wrapper,
.income-wrapper {
  position: relative;

  .switch-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    padding: 3px;
    width: 136px;
    background: #E9EDF6;
    border-radius: 16.5px;

    .switch-item {
      width: 63px;
      height: 26px;
      border-radius: 16px;
      font-size: 12px;
      color: #A8B2C5;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      cursor: pointer;

      &.active {
        background: #F7F9FC;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
        color: #4D5A73;
      }
    }
  }

  .cash-item,
  .balance-item,
  .income-item {
    margin-bottom: 8px;
    padding: 3px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4D5A73;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-bottom: 1px solid #E5E5E5;
    text-transform: uppercase;

    .value {
      font-weight: 600;
      text-transform: none;
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/components/FinancialAnalysis/components/style.scss"],"names":[],"mappings":"AAAA;;;EAGE,kBAAkB;;EAElB;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,UAAU;IACV,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,qBAAqB;;IAErB;MACE,WAAW;MACX,YAAY;MACZ,mBAAmB;MACnB,eAAe;MACf,cAAc;MACd,kBAAkB;MAClB,gBAAgB;MAChB,iBAAiB;MACjB,kBAAkB;MAClB,eAAe;;MAEf;QACE,mBAAmB;QACnB,+CAA+C;QAC/C,cAAc;MAChB;IACF;EACF;;EAEA;;;IAGE,kBAAkB;IAClB,cAAc;IACd,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,gCAAgC;IAChC,yBAAyB;;IAEzB;MACE,gBAAgB;MAChB,oBAAoB;IACtB;EACF;AACF","sourcesContent":[".cash-wrapper,\n.balance-wrapper,\n.income-wrapper {\n  position: relative;\n\n  .switch-box {\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: 2;\n    display: flex;\n    padding: 3px;\n    width: 136px;\n    background: #E9EDF6;\n    border-radius: 16.5px;\n\n    .switch-item {\n      width: 63px;\n      height: 26px;\n      border-radius: 16px;\n      font-size: 12px;\n      color: #A8B2C5;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 26px;\n      text-align: center;\n      cursor: pointer;\n\n      &.active {\n        background: #F7F9FC;\n        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);\n        color: #4D5A73;\n      }\n    }\n  }\n\n  .cash-item,\n  .balance-item,\n  .income-item {\n    margin-bottom: 8px;\n    padding: 3px 0;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    color: #4D5A73;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 20px;\n    border-bottom: 1px solid #E5E5E5;\n    text-transform: uppercase;\n\n    .value {\n      font-weight: 600;\n      text-transform: none;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
