import { useState, useEffect } from "react";
import { getDataFromPrevNode } from "src/utils/get-params";

const useConfig = (data = {}, error, isLocal) => {
  const [config, setConfig] = useState({
    title: "This months earnings",
    period: "Last month",
    showPercent: false,
    currency: "",
    data: {
      amount: 0,
      trend: 0,
    },
  });

  useEffect(() => {
    if (!error && data) {
      const {
        title = "This months earnings",
        period = "Last month",
        showPercent = false,
        currency = "",
      } = data.dataForm;

      setConfig({
        title,
        period,
        showPercent,
        currency,
        data: isLocal ? data.data : getDataFromPrevNode(data),
      });
    }
  }, [data, error]);

  return config;
};

export default useConfig;
