import { useState, useEffect } from 'react';
import { getDataFromPrevNode } from 'src/utils/get-params';

const useConfig = (data, error, isLocal) => {
  const [config, setConfig] = useState({
    data: [],
    xField: 'x',
    yField: 'y',
    point: {
      size: 5,
      shape: 'circle',
    },
  });

  useEffect(() => {
    if (!error && data) {
      const {
        xField,
        xName,
        yField,
        yName,
        groupField,
      } = data.dataForm;
      setConfig({
        data: isLocal ? data.data : getDataFromPrevNode(data),
        xField,
        yField,
        seriesField: groupField,
        isGroup: true,
        marginRatio: 0,
        label: false,
        maxColumnWidth: 20,
        legend: {
          layout: 'vertical',
          position: 'right',
          marker: {
            symbol: 'square',
            style: {
              width: '11px',
              height: '11px'
            }
          },
        },
        barStyle: {
          radius: [20, 20, 0, 0],
        },
      });
    }
  }, [data, error]);

  return {
    data: config,
  };
};

export default useConfig;
