export function formatNumber(num, fixedNumber) {
	const units = ["", "Thousand", "Million", "Billion", "Trillion", "Quadrillion", "Quintillion"];
	const length = num.toString().length;
	const unitIndex = Math.floor((length - 1) / 3);
	const value = num / Math.pow(1000, unitIndex);

	return parseFloat(value.toFixed(fixedNumber)) + " " + units[unitIndex];
}

export function formatPercent(num, fixedNumber) {
	return (num*100).toFixed(fixedNumber) + " " + '%';
}


export function toReadableString(str) {
  return str
  .split(/(?=[A-Z])/)
  .join(' ')
  .toLowerCase()
  .replace(/(^\w|\s\w)/g, m => m.toUpperCase());
}
