import PropTypes from 'prop-types';
import { Line } from '@ant-design/charts';
import { Empty } from 'antd';
import useConfig from './config'
import wrapper from 'src/modules/wrapper';

const LineCHart = ({ flow, width, height }) => {
    const config = useConfig(flow.data, flow.error, flow.isLocal);

    return (
        <>
            {config.data.data && config.data.data.length ? (
                <Line {...config.data} />
            ) : (
                <Empty />
            )}
        </>
    )
}

LineCHart.propTypes = {
    flow: PropTypes.object.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
}

export default wrapper(LineCHart);