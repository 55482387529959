import React from "react";
import wrapper from "src/modules/wrapper";
import useConfig from "./config";

function FinancialTrend({ flow, width, height }) {
  const {
    showTitle,
    itemNum,
    data: { title, contentMap },
  } = useConfig(flow.data, flow.error, flow.isLocal);

  return (
    <div className="h-full p-[16px] overflow-y-auto text-left">
      {showTitle && (
        <div className="line-clamp-2 font-bold text-[18px]/[21px]">{title}</div>
      )}
      <ul className="list-disc">
        {contentMap.slice(0, itemNum).map((text) => (
          <li className="text-[16px]/[21px] pt-[16px]">{text}</li>
        ))}
      </ul>
    </div>
  );
}

export default wrapper(FinancialTrend);
