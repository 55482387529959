// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-list {
  padding-top: 40px;

  .avatar-item {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;

    .avatar-img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    .avatar-text {
      flex: 1;
      text-align: left;
      overflow: hidden;

      .avatar-name {
        padding-bottom: 5px;
        font-size: 18px;
        font-weight: 500;
      }

      .avatar-desc {
        width: 100%;
        max-height: 45px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Avatar/style.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;;EAEjB;IACE,aAAa;IACb,SAAS;IACT,mBAAmB;;IAEnB;MACE,WAAW;MACX,YAAY;MACZ,kBAAkB;IACpB;;IAEA;MACE,OAAO;MACP,gBAAgB;MAChB,gBAAgB;;MAEhB;QACE,mBAAmB;QACnB,eAAe;QACf,gBAAgB;MAClB;;MAEA;QACE,WAAW;QACX,gBAAgB;QAChB,gBAAgB;QAChB,oBAAoB;QACpB,qBAAqB;QACrB,4BAA4B;QAC5B,uBAAuB;MACzB;IACF;EACF;AACF","sourcesContent":[".avatar-list {\n  padding-top: 40px;\n\n  .avatar-item {\n    display: flex;\n    gap: 10px;\n    margin-bottom: 20px;\n\n    .avatar-img {\n      width: 60px;\n      height: 60px;\n      border-radius: 50%;\n    }\n\n    .avatar-text {\n      flex: 1;\n      text-align: left;\n      overflow: hidden;\n\n      .avatar-name {\n        padding-bottom: 5px;\n        font-size: 18px;\n        font-weight: 500;\n      }\n\n      .avatar-desc {\n        width: 100%;\n        max-height: 45px;\n        overflow: hidden;\n        display: -webkit-box;\n        -webkit-line-clamp: 2;\n        -webkit-box-orient: vertical;\n        text-overflow: ellipsis;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
