import PropTypes from "prop-types";
import { useState } from "react";
import { Column } from "@ant-design/charts";
import wrapper from "src/modules/wrapper";
import Tabs from "./Tabs/index";
import "./style.scss";
const ColumnChart = ({ flow, width, height }) => {
  const [tab, setTab] = useState(1);
  const config = {
    data: flow.data,
    xField: "type",
    yField: "sales",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
      content: () => {
        return "";
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        formatter: (v) => {
          let month = v.split(" ")[0];
          if (month !== "一月") {
            return v.split(" ")[0];
          }
          return v;
        },
      },
    },
    yAxis: {
      label: {
        formatter: (v) => {
          return `${parseInt(v / 1000)} K`;
        },
      },
      grid: {
        line: {
          style: {
            stroke: "#DEDEDE",
            lineWidth: 1,
            opacity: 0.4,
          },
        },
      },
    },
    tooltip: {
      formatter: (datum) => {
        return { name: "", value: datum.sales };
      },
      customContent: (title, item) => {
        if (title) {
          let type = title.replace("\n\n", "").split(" ").reverse().join(" ");
          let value = `${parseInt(item[0].data.sales / 1000)} K`;
          return `<div style="width: 96px; height: 36px;background: rgba(255,255,255,0.8);padding:12px 0;">
            <div style="color:#8C8C8C;text-align: left">
              ${type}
            </div>
            <div style="color:#565759;margin-top: 15px;text-align: right">
              ${value}
            </div>
            </div>`;
        }
      },
    },
    minColumnWidth: 10,
    maxColumnWidth: 10,
    columnStyle: {
      fill: "#4996F1",
      radius: [20, 20, 0, 0],
    },
    scrollbar: {
      type: "horizontal",
    },
  };

  return (
    <>
      <Tabs tab={tab} setTab={setTab}></Tabs>
      <Column className="column-chart" {...config} />
    </>
  );
};

ColumnChart.propTypes = {
  flow: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default wrapper(ColumnChart);
