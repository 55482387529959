const getData = (category) => {
  return new Array(10)
    .fill(1)
    .map((_, index) => index + 2008)
    .map((time) => ({ time, category, value: Math.ceil(Math.random() * 100) }));
};
const mockData = ["天然气", "煤炭", "风能", "太阳能", "生物能"]
  .map((category) => getData(category))
  .flat();

export default mockData;
