import React, { useEffect, useState } from "react";
import { Collapse, Space, Spin } from 'antd';
// import 'antd/dist/antd.css'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import wrapper from "src/modules/wrapper";
import "./style.scss";
import useConfig from "./config.js";
import Income from "./components/income";
import Balance from "./components/balance";
import Cash from "./components/cash";
import voiceIcon from 'src/assets/answer.gif';
import useAI from 'src/hooks/useAI';

dayjs.extend(utc);

const promptGen = (data, aiAgentName) => `
你是一个${aiAgentName}的分析师，以下数据是行业中其他企业的实时金融数据:
${JSON.stringify(data)}
请用专业的语言，先用一段文字总结你获得的数据，再分析这些数据对${aiAgentName}的潜在影响。
请用英文回答我
`

function FinancialAnalysis({ flow, company, aiAgentName, width, height }) {
  const config = useConfig(flow.data, flow.error, flow.isLocal);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const ai = useAI();
  const getCompanyData = (data) => {
    setData(data)
  }

  useEffect(() => {
    if (data && data.length) {
      ai.chat(promptGen(data, aiAgentName))
    }
  }, [data])

  useEffect(() => {
    if (data && data.length && refresh) {
      setRefresh(false)
      ai.chat(promptGen(data, aiAgentName))
    }
  }, [refresh, data])

  return (
    <div className="financial-analysis-wrapper">
      <div className="financial-analysis-header">
        <img
          src={voiceIcon}
          alt=""
          className="icon"
        />
        <span className="title">
          AI FINANCIAL Analysis
        </span>
      </div>
      <Spin
        delay={1000}
        wrapperClassName="competitive-analysis-list-box"
        tip="AI is summarizing..."
        spinning={ai.loading}
      >
        {ai.error ?
          <div className="ai-content-refresh" onClick={() => setRefresh(true)}>
            Something went wrong, please try again...
          </div> :
          <div className="ai-content">
            {ai.answer}
          </div>}
      </Spin>
      <div className="line-box">
        <span className="text">Citation</span>
        <span className="line"></span>
      </div>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Collapse
          activeKey={'Income Statement'}
          collapsible="header"
          expandIconPosition='end'
          expandIcon={() => <></>}
          ghost
          className="site-collapse-custom-collapse"
        >
          <Collapse.Panel header="Income Statement" key="Income Statement" className="site-collapse-custom-panel" forceRender={true}>
            <Income getCompanyData={getCompanyData} company={company} />
          </Collapse.Panel>
        </Collapse>

        <Collapse
          activeKey={'balance sheet'}
          collapsible="header"
          expandIconPosition='end'
          expandIcon={() => <></>}
          ghost
          className="site-collapse-custom-collapse"
        >
          <Collapse.Panel header="balance sheet" key="balance sheet" className="site-collapse-custom-panel" forceRender={true}>
            <Balance company={company} />
          </Collapse.Panel>
        </Collapse>

        <Collapse
          activeKey={'CASH FLOW'}
          collapsible="header"
          expandIconPosition='end'
          expandIcon={() => <></>}
          ghost
          className="site-collapse-custom-collapse"
        >
          <Collapse.Panel header="CASH FLOW" key="CASH FLOW" className="site-collapse-custom-panel" forceRender={true}>
            <Cash company={company} />
          </Collapse.Panel>
        </Collapse>
      </Space>
    </div>

  );
}

export default wrapper(FinancialAnalysis);
