import PropTypes from 'prop-types';
import { useState } from 'react';
import cx from 'classnames';
import { Empty } from 'antd';

import { Pie } from '@ant-design/charts';
import useConfig from './config';
import wrapper from 'src/modules/wrapper';

import './style.scss';

/**
 * @param {chartConfig} flow - chartConfig 图表配置参数
 * @param {object} flow - useFlow hooks return
 * @param {number} width - CircularChart width
 * @param {number} height - CircularChart height
 * @param {array} filter - CircularChart filter value if filter is null don't show filter  example:
 *                         [{label: '月度', active: true,aRP: {id:1 }}, {label: '季度', aRP: {id:1 }}]
 *                         label 显示文案 aRP就是 active 默认选中第一个 API Request Parameters 接口请求传递参数
 *
 */

const chartsParam = {
  appendPadding: [0, 0, 20, 0],
  data: [],
  angleField: 'time',
  colorField: 'volume',
  radius: 1,
  innerRadius: 0.64,
  legend: {
    layout: 'horizontal',
    position: 'bottom',
    marker: {
      symbol: 'square',
      style: {
        width: '11px',
        height: '11px'
      }
    },
  },
  label: {
    type: 'inner',
    offset: '-50%',
    style: {
      textAlign: 'center',
    },
    autoRotate: false,
    content: '{value}',
  },
  statistic: {
    title: {
      style: {
        fontSize: '16px',
      },
      content: '总计',
    },
    content: {
      style: {
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  interactions: [
    {
      type: 'element-selected',
    },
    {
      type: 'element-active',
    }
  ],
}

const CircularChart = (props) => {
  const { chartConfig, flow, width, height, filter } = props;
  const config = useConfig(flow.data, flow.error, flow.isLocal);
  const [filterActive, setFilterActive] = useState(filter && filter.filter((it) => it.active)[0].label);
  chartsParam.data = config.data;
  return (
    config.data.data && config.data.data.length ? (
      filter && filter.length ? (
        <div className="pie-wrapper">
          <div className="filter-wrap">
            {filter.map((item, key) =>
              <span key={key}
                onClick={async () => {
                  if (filterActive !== item.label) {
                    setFilterActive(item.label);
                    await flow.refresh(item.label);
                  }
                }}
                className={cx({ active: filterActive === item.label })}>{item.label}</span>
            )}
          </div>
          <div className="pie-wrap" style={{ height: height * 0.7 }}>
            <Pie {...config.data} {...chartConfig} />
          </div>
        </div>
      ) : (
        <Pie {...config.data} {...chartConfig} />
      )
    ) : (
      <Empty />
    )
  );
};

CircularChart.propTypes = {
  flow: PropTypes.object.isRequired,
  filter: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default wrapper(CircularChart);
