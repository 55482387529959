import { useState, useEffect } from 'react';
import { getDataFromPrevNode } from 'src/utils/get-params';

const useConfig = (data, error, isLocal) => {
  const [config, setConfig] = useState([]);

  useEffect(() => {
    if (!error && data) {
      const field = data.data;
      const meta = data.meta;
      const dataForm = data.dataForm;
      const newData = isLocal ? data.data : getDataFromPrevNode(data);
      setConfig({
        data: newData || [],
        angleField: dataForm.xField || '',
        colorField: dataForm.yField || '',
      });
    }
  }, [data, error]);

  return {
    data: config,
  };
};

export default useConfig;
