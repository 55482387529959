(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("antd"), require("react"), require("@ant-design/charts"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("WemoCards", ["antd", "react", "@ant-design/charts", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["WemoCards"] = factory(require("antd"), require("react"), require("@ant-design/charts"), require("react-dom"));
	else
		root["WemoCards"] = factory(root["antd"], root["react"], root["@ant-design/charts"], root["react-dom"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__2721__, __WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__3577__, __WEBPACK_EXTERNAL_MODULE__7111__) => {
return 