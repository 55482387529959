export const themeColors = [
    {
        key: 'LightPurple',
        name: "浅紫色",
        color: ['#F2E9FC', '#DFC6F7', '#D1AEF5', '#BE8CF1', '#AF71EE', '#A863ED', '#9F4BF2', '#8D40DA', '#7C36C3', '#6D35A6'],
    },

    {
        key: 'DeepPurple',
        name: "深紫色",
        color: ['#ECEAFD', '#DBD6FC', '#CCC5FB', '#A398F4', '#887BE9', '#7868E6', '#6354CA', '#5043AE', '#3F3391', '#2F2574'],
    },

    {
        key: 'DarkBlue',
        name: "深蓝色",
        color: ['#E2E9FD', '#C5D2FB', '#A2B8F9', '#88A4F8', '#6F91F7', '#6085F7', '#4A6CD6', '#3855B5', '#284194', '#1A2E73'],
    },

    {
        key: 'Lightblue',
        name: "浅蓝色",
        color: ['#E8F6FE', '#D0EEFD', '#ABE0FC', '#94D7FB', '#89D3FB', '#77CAFA', '#61ADD8', '#4C8FB7', '#3B7CA5', '#2A5E80'],
    },

    {
        key: 'Darkgreen',
        name: "深绿色",
        color: ['#E6F5EF', '#C6EBDB', '#9EDBC0', '#82D0AC', '#6DC59A', '#61BF8F', '#4D9972', '#397255', '#31644A', '#254D3A'],
    },

    {
        key: 'Lightgreen',
        name: "浅绿色",
        color: ['#E6F4DC', '#D0F4B7', '#ADE784', '#94DE62', '#82D64C', '#72C240', '#5AA031', '#447E22', '#37631C', '#25460F'],
    },

    {
        key: 'Lightyellow',
        name: "浅黄色",
        color: ['#FEF9E5', '#FDF2C7', '#FCECAE', '#FBE694', '#F9DD76', '#F8D65A', '#F7CF4C', '#F3C745', '#DDB53D', '#AF8F2E'],
    },

    {
        key: 'DarkYellow',
        name: "深黄色",
        color: ['#FDF6E7', '#FBE6BC', '#F9D994', '#F7CB6E', '#F5BD4F', '#F3AF3D', '#F2A53F', '#F09438', '#CD7B2C', '#A16020'],
    },
]