import { lazy } from 'react'

// project imports
import Loadable from 'ui-component/loading/Loadable'
import MinimalLayout from 'layout/MinimalLayout'

// canvas routing
const Preview = Loadable(lazy(() => import('views/preview')))

// ==============================|| Preview ROUTING ||============================== //

const PreviewRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/preview',
            element: <Preview />
        },
    ]
}

export default PreviewRoutes
