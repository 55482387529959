// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-box {
  width: 201px;
  height: 32px;
  border-radius: 16.5px;
  background: #e9edf6;
  display: flex;
  padding: 3px;
  box-sizing: border-box;
  float: right;
  position: relative;
  z-index: 2;
  user-select: none;
  .tabs-item {
    flex: 1;
    height: 26px;
    border-radius: 16px;
    color: #A8B2C5;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    cursor: pointer;
  }
  .tabs-item-select {
    color: #4D5A73;
    background: #f7f9fc;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ColumnChart/Tabs/index.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB;IACE,OAAO;IACP,YAAY;IACZ,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;EACjB;EACA;IACE,cAAc;IACd,mBAAmB;IACnB,+CAA+C;EACjD;AACF","sourcesContent":[".tabs-box {\n  width: 201px;\n  height: 32px;\n  border-radius: 16.5px;\n  background: #e9edf6;\n  display: flex;\n  padding: 3px;\n  box-sizing: border-box;\n  float: right;\n  position: relative;\n  z-index: 2;\n  user-select: none;\n  .tabs-item {\n    flex: 1;\n    height: 26px;\n    border-radius: 16px;\n    color: #A8B2C5;\n    font-size: 12px;\n    line-height: 26px;\n    text-align: center;\n    cursor: pointer;\n  }\n  .tabs-item-select {\n    color: #4D5A73;\n    background: #f7f9fc;\n    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
