import React, { createContext, useState, useContext } from 'react'

const NodesContext = createContext()

export const NodesProvider = ({ children }) => {
    const [nodes, setNodes] = useState([])

    return <NodesContext.Provider value={{ nodes, setNodes }}>{children}</NodesContext.Provider>
}

export const NodesConsumer = ({ children }) => {
    return <NodesContext.Consumer>{children}</NodesContext.Consumer>
}

export const useNodes = () => {
    const context = useContext(NodesContext)
    if (!context) {
        throw new Error('useNodes must be used within a NodesContext')
    }
    return context
}
