// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wemo-flow-spin {
    height: 100%;

    .ant-spin-container {
        height: 100%;
    }
}

.wemo-flow-error {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/modules/Error/style.scss"],"names":[],"mappings":"AAAA;IACI,YAAY;;IAEZ;QACI,YAAY;IAChB;AACJ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".wemo-flow-spin {\n    height: 100%;\n\n    .ant-spin-container {\n        height: 100%;\n    }\n}\n\n.wemo-flow-error {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
