import "./theme.scss";

export { default as Layout } from "src/modules/Layout";
export { default as LineChart } from "src/components/LineChart";
export { default as Price } from "src/components/Price";
export { default as ColumnChart } from "src/components/ColumnChart";
export { default as ColumnGroupedChart } from "src/components/ColumnGroupedChart";
export { default as CircularChart } from "src/components/CircularChart";
export { default as CurveChart } from "src/components/CurveChart";
export { default as LiquidChart } from "src/components/LiquidChart";
export { default as Text } from "src/components/Text";
export { default as Avatar } from "src/components/Avatar";
export { default as CompetitiveAnalysis } from "src/components/CompetitiveAnalysis";
export { default as FinancialAnalysis } from "src/components/FinancialAnalysis";
export { default as News } from "src/components/News";
export { default as FinancialTrend } from "src/components/FinancialTrend";
export { default as LongText } from "src/components/LongText";

//  仅文字卡片
export { default as ImageText1 } from "src/components/ImageText/ImageText1";
// 大缩略图+文字卡片
export { default as ImageText2 } from "src/components/ImageText/ImageText2";
// 大缩略图+小缩略图+文字卡片
export { default as ImageText3 } from "src/components/ImageText/ImageText3";
// 图文卡片
export { default as ImageText4 } from "src/components/ImageText/ImageText4";

// 双折线图
export { default as TwoLinesChart } from "src/components/MultiLineChart/TwoLinesChart";
// 三折线图
export { default as ThreeLinesChart } from "src/components/MultiLineChart/ThreeLinesChart";
// 单折线双柱状双轴图
export { default as OneLineTwoColumnChart } from "src/components/DualAxesChart/OneLineTwoColumnChart";
// 双折线单柱状双轴图
export { default as TwoLineOneColumnChart } from "src/components/DualAxesChart/TwoLineOneColumnChart";

// 基础信息
export { default as BasicInfo } from "src/components/BasicInfo";

// 媒体
export { default as Image } from "src/components/Image";
export { default as Video } from "src/components/Video";
export { default as Link } from "src/components/Link";

// AI
export { default as AiText } from "src/components/AiText";

import { set, get } from "src/config"

export const config = { set, get };