import { useState, useEffect } from "react";
import { getDataFromPrevNode } from 'src/utils/get-params';

const useConfig = (data = {}, error, isLocal) => {
  const [config, setConfig] = useState({
    data: [],
  });

  useEffect(() => {
    if (!error && data) {
      setConfig({
        data: isLocal ? data.data : getDataFromPrevNode(data),
      });
    }
  }, [data, error]);

  return config;
};

export default useConfig;
