import { useState, useEffect } from "react";
import { getDataFromPrevNode } from 'src/utils/get-params';

const useConfig = (data = {}, error, isLocal) => {
  const [config, setConfig] = useState({
    showTitle: true,
    itemNum: 3,
    data: {
      title: "",
      contentMap: [],
    },
  });

  useEffect(() => {
    if (!error && data) {
      const { showTitle = true, itemNum = 3 } = data.dataForm;
      const { title, contentMap } = isLocal ? data.data : getDataFromPrevNode(data) || {};

      setConfig({
        showTitle,
        itemNum,
        data: {
          title,
          contentMap,
        },
      });
    }
  }, [data, error]);

  return config;
};

export default useConfig;
