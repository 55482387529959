const timeArray = new Array(5).fill(1).map((_, index) => index + 2008);
const getData = (field) => {
  return timeArray.map((time) => ({
    time,
    value: Math.ceil(Math.random() * 100),
    ...field,
  }));
};
export const mockLineData = ["天然气", "煤炭", "风能", "太阳能", "生物能"]
  .map((category) => getData({ category }))
  .flat();
export const mockColumnData = [
  ...getData({ type: "UV" }),
  ...getData({ type: "BILL" }),
];
