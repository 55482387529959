import { useState, useEffect } from 'react';
import cx from 'classnames';
// import { Empty } from 'antd';
import { Column } from '@ant-design/charts';

import "./style.scss";
import request, { API_KEY } from 'src/adapters/fixed';
import Error from 'src/modules/Error';
import { formatNumber, toReadableString } from 'src/utils/tools';

const BalancePanel = ({ company }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState([])
  const [chartData, setChartData] = useState([])
  const [period, setPeriod] = useState('quarter');
  const [current, setSelected] = useState({});

  async function _fetchData(){
    setLoading(true)
    const result = await request.get(`/v3/balance-sheet-statement/${company.symbol || company.cik}`, {
      period: period,
      limit: 5,
      apikey: API_KEY
    })
    setLoading(false)
    const cacheData = (result.data || []).reverse()
    setError(!cacheData?.length)
    const cacheChartArray = cacheData.map((it)=>
                          [{
                            value: it.totalCurrentAssets,
                            date: it.date,
                            type: 'TOTAL ASSETS'
                          }, {
                            value: it.totalLiabilities,
                            date: it.date,
                            type: 'TOTAL LIABILITIES'
                          }]).flat()
    setSelected(cacheData[0])
    setChartData(cacheChartArray)
    setData(cacheData)
  }
  useEffect(()=>{
    _fetchData()
  },[period])

  const chartConfig = {
    data: chartData,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    height: 217,
    locale: 'en-US',
    isGroup: true,
    color: ['#4285F4', '#FBBD05'],
    appendPadding: [12, 20, 0, 0],
    columnWidthRatio: 0.43,
    // dodgePadding: 3,
    minColumnWidth: 22,
    maxColumnWidth: 22,
    theme: {
      geometries: {
        interval: {
          rect: {
            active: {
              style: {
                stroke: '#fff',
                lineWidth: 0,
              }
            },
            default: {
              style: {},
            },
            label: {
              style: {
                fill: '#fff', // Set the text color
                backgroundColor: '#1890ff', // Set the background color for labels
                padding: 8, // Adjust padding as needed
                borderRadius: 4, // Optional: Set border radius for rounded corners
              },
            },
          }
        },
      },
    },
    columnStyle: {
      radius: [5, 5, 0, 0],
      lineWidth: 5,
    },
    legend: {
      position: 'top-right',
      offsetY: 2,
    },
    tooltip: {
      formatter: (datum) =>{
        return {name: datum.type, value: formatNumber(datum.value, 3)}
      }
    },
    yAxis: {
      position: 'right',
      // tickCount: 3,
      label:{
        formatter: (text) =>{
          return formatNumber(text, 0)
        }
      },
      grid:{
        line: {
          style: {
            lineWidth: 1,
            stroke: '#f9f9f9',
            opacity: 1,
          }
        },
      },
    },
    xAxis: {
      top: true,
      label: {
        offsetY: 10,
        style: (text, item, index) => {
          if(text === current?.date){
            return {
              fill: '#2C3444',
              fontWeight: 500,
              cursor: "pointer"
            }
          }
          return {
            fill: '#4D5A73',
            cursor: "pointer"
          }
        }
      },
      line: {
        style: {
          lineWidth: 1,
          stroke: 'black',
          opacity: 1,
        }
      },
      tickLine: {
        length: 5,
        style: {
          lineWidth: 2,
          stroke: 'black',
        }
      }
    },
    onReady: (plot) => {
      plot.on('axis-label:click', (ev) => {
        const item = ev.gEvent.delegateObject.item.name
        if (/-/.test(item)) {
          setSelected(data.filter((v)=> v.date === item)[0])
        }
      });
    }
  }
  return error ? <Error /> : <div className='balance-wrapper'>
    <div className='switch-box'>
      <span className={cx('switch-item', period === 'quarter' && 'active')} onClick={()=>setPeriod('quarter')}>Quarterly</span>
      <span className={cx('switch-item', period === 'annual' &&  'active')} onClick={()=>setPeriod('annual')} >Yearly</span>
    </div>
    <Column {...chartConfig} />
    <br />
    { current.date &&
    <>
      {Object.keys(current).map((key)=>
        typeof current[key] === 'number' && <div key={key} className='balance-item'>
          <span>{toReadableString(key)}</span>
          <span className='value'>{formatNumber(current[key], 3)}</span>
        </div>
      )}
    </>
    }
  </div>
};


export default BalancePanel;
