import PropTypes from 'prop-types';
import { useState } from 'react';
import cx from 'classnames';
import { Empty } from 'antd';
import { Liquid } from '@ant-design/charts';

import useConfig from './config';
import wrapper from 'src/modules/wrapper';

import './style.scss';

/**
 * @param {chartConfig} flow - chartConfig 图表配置参数
 * @param {object} flow - useFlow hooks return
 * @param {number} width - LiquidChart width
 * @param {number} height - LiquidChart height
 * @param {array} filter - LiquidChart filter value if filter is null don't show filter  example:
 *                         [{label: '月度', active: true,aRP: {id:1 }}, {label: '季度', aRP: {id:1 }}]
 *                         label 显示文案 aRP就是 active 默认选中第一个 API Request Parameters 接口请求传递参数
 *
 */

const LiquidChart = (props) => {
  const { chartConfig = {}, flow, width, height, filter } = props;
  const config = useConfig(flow.data, flow.error, flow.isLocal);
  const [filterActive, setFilterActive] = useState(
    filter && filter.filter((it) => it.active)[0].label
  );
  return config.data.dataInitialization ? (
    filter && filter.length ? (
      <div className="liquid-wrapper">
        <div className="filter-wrap">
          {filter.map((item, key) => (
            <span
              key={key}
              onClick={async () => {
                if (filterActive !== item.label) {
                  setFilterActive(item.label);
                  await flow.refresh(item.label);
                }
              }}
              className={cx({ active: filterActive === item.label })}
            >
              {item.label}
            </span>
          ))}
        </div>
        <div className="pie-wrap" style={{ height: height * 0.7 }}>
          <Liquid {...config.data} {...chartConfig} />
        </div>
      </div>
    ) : (
      <Liquid {...config.data} {...chartConfig} />
    )
  ) : (
    <Empty />
  );
};

LiquidChart.propTypes = {
  flow: PropTypes.object.isRequired,
  filter: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default wrapper(LiquidChart);
