import React from "react";
import wrapper from "src/modules/wrapper";
import useConfig from "./config";
import ImageTextContent from "../ImageTextContent";

function ImageText({ flow, width, height }) {
  const { itemNum, data } = useConfig(flow.data, flow.error, flow.isLocal);

  return (
    <div className="h-full overflow-y-auto">
      <ImageTextContent {...data} itemNum={itemNum} title={null} />
    </div>
  );
}

export default wrapper(ImageText);
