import { useState, useEffect } from 'react';
import { getDataFromPrevNode } from 'src/utils/get-params';

const useConfig = (data, error, isLocal) => {
    const [config, setConfig] = useState('');

    useEffect(() => {
        if (!error && data && data.dataForm) {
            const { url } = data.dataForm;
            setConfig(url)
        }
    }, [data, error])

    return {
        data: config,
    };
}

export default useConfig;