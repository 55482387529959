import React from "react";
import wrapper from "src/modules/wrapper";
import downIcon from "src/assets/down.svg";
import upIcon from "src/assets/up.svg";
import minusIcon from "src/assets/minus.svg";
import useConfig from "./config";

const trendConfig = {
  down: {
    icon: downIcon,
    bgColor: "#FFEDED",
    textColor: "#AF3636",
  },
  zero: {
    icon: minusIcon,
    bgColor: "#FFFAED",
    textColor: "#F3AF3D",
  },
  up: {
    icon: upIcon,
    bgColor: "#EDFFF9",
    textColor: "#36AF8B",
  },
};

function FinancialTrend({ flow, width, height }) {
  const {
    title,
    period,
    showPercent,
    currency,
    data: { amount, trend },
  } = useConfig(flow.data, flow.error, flow.isLocal);

  const trendType = trend < 0 ? "down" : trend === 0 ? "zero" : "up";
  const { icon, bgColor, textColor } = trendConfig[trendType];

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div>
        <div className="text-[18px] leading-[19px] font-bold">{title}</div>
        <div className="text-[48px] leading-[68px] font-semibold mt-[12px] mb-[20px]">
          {currency}
          {amount}
        </div>
        <div
          className="flex justify-center items-center w-[120px] h-[40px] rounded-[20px] mx-auto text-[20px] font-semibold mb-[14px]"
          style={{ background: bgColor, color: textColor }}
        >
          <img src={icon} alt="trend" className="mr-[8px]" />
          {Math.abs(trend)}
          {showPercent && "%"}
        </div>
        <div className="text-[18px] leading-[19px] font-bold"> {period}</div>
      </div>
    </div>
  );
}

export default wrapper(FinancialTrend);
