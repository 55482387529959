import { useState } from 'react'
import chater from 'src/utils/ai-chat';

/**
 * @param {string} ResultId - ResultId
 * @param {string} TableId - TableId by flow runed
 * @param {string} NodeId - id of node
 *
 * @returns {object} hooks return
 */
const useAI = () => {
    const [answer, setAnswer] = useState('')
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    async function chat(query) {
        if (loading) return;
        setLoading(true)
        setError(null)
        try {
            const result = await chater(query);
            setAnswer(result.answer);
            setError(null)
        } catch (e) {
            setError(e.message);
        }
        setLoading(false)
    }

    return {
        chat,
        answer, // 数据
        loading, // loading的状态
        error, // error
    }
}

export default useAI;