import { useEffect, useRef, useState } from 'react'

const COUNTDOWN_SECONDS = 60

const useCountDown = () => {
  const intervalRef = useRef(null)
  const [timeCount, setTimeCount] = useState(false)

  const [second, setSecond] = useState(COUNTDOWN_SECONDS)

  useEffect(() => {
    if (timeCount) {
      intervalRef.current = setInterval(() => {
        setSecond((preSecond) => {
          if (preSecond <= 1) {
            setTimeCount(false)
            clearInterval(intervalRef.current)
            return COUNTDOWN_SECONDS
          }
          else {
            return preSecond - 1
          }
        })
      }, 1000)
    }
    return () => {
      if (intervalRef.current)
        clearInterval(intervalRef.current)
    }
  }, [timeCount])

  const startCount = (seconds) => {
    setTimeCount(true)
    seconds && setSecond(seconds)
  }

  return { countTime: second, startCount, showCount: timeCount }
}

export default useCountDown
