// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.curve-wrapper {
  display: flex;
  flex-direction: column;

  .filter-wrap {
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 201px;
    height: 32px;
    border-radius: 16.5px;
    background: #e9edf6;

    span {
      width: 63px;
      height: 26px;
      border-radius: 16px;
      color: #a8b2c5;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      cursor: pointer;
      transition: all ease-in-out 0.15s;

      &.active {
        color: #4d5a73;
        background: #f7f9fc;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
      }
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CurveChart/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;;EAEtB;IACE,aAAa;IACb,oBAAoB;IACpB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,qBAAqB;IACrB,mBAAmB;;IAEnB;MACE,WAAW;MACX,YAAY;MACZ,mBAAmB;MACnB,cAAc;MACd,eAAe;MACf,kBAAkB;MAClB,gBAAgB;MAChB,iBAAiB;MACjB,eAAe;MACf,iCAAiC;;MAEjC;QACE,cAAc;QACd,mBAAmB;QACnB,+CAA+C;MACjD;IACF;EACF;AACF","sourcesContent":[".curve-wrapper {\n  display: flex;\n  flex-direction: column;\n\n  .filter-wrap {\n    display: flex;\n    align-self: flex-end;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 20px;\n    width: 201px;\n    height: 32px;\n    border-radius: 16.5px;\n    background: #e9edf6;\n\n    span {\n      width: 63px;\n      height: 26px;\n      border-radius: 16px;\n      color: #a8b2c5;\n      font-size: 12px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 26px;\n      cursor: pointer;\n      transition: all ease-in-out 0.15s;\n\n      &.active {\n        color: #4d5a73;\n        background: #f7f9fc;\n        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
