import request from './fetch';

const chat = request('https://gpt.metaio.cc/v1/chat-messages');
const apiKey = "app-MqCjuQTgNNsoKqDMxkaQTcjk";
const appId = "470977df-bcdb-4498-9b79-4c5dc27b3b06";

export default async function (query) {
    const result = await chat.post('', {
        inputs: {},
        query,
        response_mode: "blocking",
        conversation_id: null,
        user: "wemo-flow",
        appId: appId
    }, {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${apiKey}`
    })
    return result.data;
}
