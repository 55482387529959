import { SketchPicker } from 'react-color'
import { Select, Popover, Switch } from 'antd'
import { themeColors } from 'src/constants/color';
import { fonts } from 'src/constants/font';

import './style.scss'

const Item = ({ children, name }) => {
    return (
        <div className='setting-model-item'>
            <div className='setting-model-item-title'>{name}</div>
            {children}
        </div>
    )
}

const ColorPicker = ({ color, onChangeComplete }) => {
    return <Popover
        bordered={false}
        style={{
            width: "100%",
            border: '2px solid #2C3444',
            borderRadius: 10000,
        }}
        arrow={false}
        placement="bottom"
        title={null}
        dropdownStyle={{ borderRadius: 6 }}
        trigger="click"
        content={<SketchPicker color={color} onChangeComplete={c => onChangeComplete(c)} />}
    >
        <div className='color-picker-overview-layout'>
            <div className='color-picker-overview' style={{ backgroundColor: `${color}` }} />
        </div>
    </Popover>
}

export const SettingModel = ({
    setThemeColor,
    textColor,
    setTextColor,
    bgColor,
    setBgColor,
    showTitle,
    setShowTitle,
    fontFamily,
    setFontFamily,
}) => {
    return (
        <div className='setting-model-container'>
            <Item name="图表颜色">
                <Select
                    bordered={false}
                    style={{
                        width: "100%",
                        border: '2px solid #2C3444',
                        borderRadius: 10000,
                    }}
                    dropdownStyle={{ borderRadius: 6 }}
                    onChange={v => setThemeColor(v)}
                >
                    {themeColors.map(item => (
                        <Select.Option value={item.key} key={item.name}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {item.color.map(color => <div
                                    key={color}
                                    style={{
                                        backgroundColor: color,
                                        width: 16,
                                        height: 16,
                                        float: 'left',
                                    }}
                                />)}
                            </div>
                        </Select.Option>
                    ))}
                </Select>
            </Item>
            <Item name="背景颜色">
                <ColorPicker color={bgColor} onChangeComplete={c => {
                    setBgColor(c.hex);
                }} />
            </Item>
            <Item name="标题">
                <div className='setting-model-title-edit'>
                    <span>显示标题</span>
                    <Switch checked={showTitle} onChange={v => setShowTitle(v)} />
                </div>
                <div>
                    <Select
                        bordered={false}
                        style={{
                            width: "100%",
                            border: '2px solid #2C3444',
                            borderRadius: 10000,
                            marginTop: 9,
                            marginBottom: 7,
                        }}
                        dropdownStyle={{ borderRadius: 6 }}
                        value={fontFamily}
                        onChange={v => setFontFamily(v)}
                    >
                        {fonts.map(font => <Select.Option key={font} value={font}>{font}</Select.Option>)}
                    </Select>
                </div>
                <div>
                    <ColorPicker color={textColor} onChangeComplete={c => {
                        setTextColor(c.hex);
                    }} />
                </div>
            </Item>
        </div>
    )
}
