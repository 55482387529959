import React, { useMemo } from "react";
import { Line } from "@ant-design/plots";

const BaseLine = (props) => {
  const { data, xField, yField, seriesField, seriesFieldNum = 2 } = props;

  // 根据seriesFieldNum过滤data
  const newData = useMemo(() => {
    const filterFields = [
      ...new Set(data.map((item) => item[seriesField])),
    ].slice(0, seriesFieldNum);
    const newData = data.filter((item) =>
      filterFields.includes(item[seriesField])
    );
    return newData;
  }, [data, seriesField, seriesFieldNum]);

  const config = useMemo(
    () => ({
      data: newData,
      xField,
      yField,
      seriesField,
      xAxis: {
        // type: "time",
      },
      yAxis: {
        label: {
          // 数值格式化为千分位
          // formatter: (v) =>
          //   `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
        },
      },
      smooth: true,
      legend: {
        marker: {
          symbol: (x, y, r) => [
            ["M", x - r, y],
            ["L", x + r, y],
          ], // 设置图例标记为水平线
        },
      },
    }),
    [newData, seriesField, xField, yField]
  );

  return (
    <div className="h-full text-left">
      <Line {...config} />
    </div>
  );
};

export default BaseLine;
