import PropTypes from "prop-types"
import { Popover } from 'antd';
import SettingIcon from 'src/assets/setting.png';
import OpenIcon from 'src/assets/open.png';
import { SettingModel } from 'src/modules/Setting';

import "./style.scss";

const Layout = ({
    domId,
    // children
    children,

    // props
    title,
    width,
    height,
    fullHeight,
    noBackground,
    noShadow,
    showHeader,
    showIllustrate,
    illustrate,
    scrollY,
    scrollX,
    styles,

    // outside state
    setThemeColor,
    setTextColor,
    textColor,
    bgColor,
    setBgColor,
    showTitle,
    setShowTitle,
    fontFamily,
    setFontFamily,
}) => {
    let customStyle = {};
    if (bgColor) customStyle.backgroundColor = bgColor;
    if (typeof width === 'number' || typeof width === 'string') customStyle.width = width;
    if (noBackground) customStyle.background = 'rgba(0,0,0,0)';
    if (noShadow) customStyle.boxShadow = 'none';

    if (scrollY) customStyle.overflowY = 'scroll';
    if (scrollX) customStyle.overflowX = 'scroll';

    if (fullHeight && (typeof height === 'number' || typeof height === 'string')) customStyle.height = height;
    if (styles) customStyle = { ...styles, ...customStyle };
    return (
        <div id={`wemo-cards-${domId}`} className='wemo-card-layout' style={customStyle}>
            {/* 标题部分 */}
            {
                showHeader && (
                    <div className="wemo-card-title">
                        <span
                            style={{ fontFamily: fontFamily, color: textColor }}
                            className="wemo-card-title-label"
                        >{showTitle && title ? title : ""}</span>
                        <div className="wemo-card-title-placeholder"></div>
                        {/* <img alt="" className="wemo-card-title-open" src={OpenIcon} /> */}
                        <Popover
                            arrow={false}
                            placement="rightTop"
                            title={null}
                            content={
                                // Setting Model
                                <SettingModel
                                    fontFamily={fontFamily}
                                    setFontFamily={setFontFamily}
                                    showTitle={showTitle}
                                    setShowTitle={setShowTitle}
                                    textColor={textColor}
                                    setThemeColor={setThemeColor}
                                    setTextColor={setTextColor}
                                    setBgColor={setBgColor}
                                    bgColor={bgColor}
                                />}
                            trigger="click"
                        >
                            <img alt="" className="wemo-card-title-setting" src={SettingIcon} />
                        </Popover>
                    </div>
                )
            }
            {/* 组件容器 */}
            {showIllustrate && illustrate ? (
                <span
                    style={{ color: textColor }}
                    className="wemo-card-illustrate"
                >
                    {illustrate}
                </span>
            ) : null}
            <div className="wemo-card-content" style={{
                height: typeof height === 'number' ? height - 32 : `calc(${height} - ${showIllustrate && illustrate ? 64 : 32}px)`,
            }}>
                {children}
            </div>
        </div >
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    bgColor: PropTypes.string,
    bgOpacity: PropTypes.bool,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
}

export default Layout;