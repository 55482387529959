import { useState, useEffect } from "react";
import mockData from "../mockData";
// import getParams from "src/utils/get-params";

const useConfig = (data, error, isLocal) => {
  const [config, setConfig] = useState({
    data: [],
    xField: "x",
    yField: "y",
    seriesField: "",
  });

  useEffect(() => {
    if (!error && data) {
      //   const field = data.data;
      //   const meta = data.meta;
      //   const newData = getParams(field, meta);
      const {
        xField = "time",
        yField = "value",
        seriesField = "category",
      } = data.dataForm;
      setConfig({
        data: mockData,
        xField,
        yField,
        seriesField,
      });
    }
  }, [data, error]);

  return config;
};

export default useConfig;
