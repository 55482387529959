import PropTypes from 'prop-types';
import { Line } from '@ant-design/charts';
import { Empty } from 'antd';
import useConfig from './config'
import wrapper from 'src/modules/wrapper';

const LineCHart = ({ flow, width, height }) => {
    const config = useConfig(flow.data, flow.error, flow.isLocal);

    return (
        <>
            {config.data ? (
                <video style={{ width: '100%' }} src={config.data} />
            ) : (
                <Empty />
            )}
        </>
    )
}

LineCHart.propTypes = {
    flow: PropTypes.object.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
}

export default wrapper(LineCHart);