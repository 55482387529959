const isArray = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Array]';
}

export const getDataFromPrevNode = ({ data, meta }) => getParamsByPretreament(data, meta);

// TODO: 取消导出 改用getDataFromPrevNode方法
export default function getParamsByPretreament(paths, data, id = 'main') {
    let valueObj = data;
    const path = isArray(paths) ? paths : paths[id];
    try {
        const result = {};
        for (let i = 0; i < path.length; i++) {
            const currentPath = JSON.parse(path[i]);
            if (!isArray(currentPath)) throw new Error('not Array');
            result[currentPath[currentPath.length - 1]] = getParamsByPretreament(currentPath, data, id);
        }
        return result;
    } catch (e) {
        if (e.message === 'not Array') throw new Error('数据获取失败,请重试.')
        for (let i = 0; i < path.length; i++) {
            if (valueObj[path[i]]) {
                valueObj = valueObj[path[i]];
            } else {
                return null;
            }
        }
        return valueObj;
    }
}