import { useState, useEffect } from 'react';
import { getDataFromPrevNode } from 'src/utils/get-params';

const useConfig = (data = {}, error, isLocal) => {
    const [config, setConfig] = useState(0);
    const [unit, setUnit] = useState('$');
    const [localError, setError] = useState(null);

    useEffect(() => {
        if (!error && !localError && data) {
            try {
                const dataForm = data.dataForm;
                const newData = isLocal ? data.data : getDataFromPrevNode(data);

                const [int, decimal] = `${newData || 0}`.split('.');
                const newPrice = `${int}.${decimal.slice(0, 2)}`;
                setConfig(newPrice || 0)
                setUnit(dataForm.unit || '$');
                setError(null);
            } catch (e) {
                setError(e);
            }
        }
    }, [data, error, localError])

    return {
        data: config,
        unit,
    };
}

export default useConfig;