
const store = {};

export default class Cache {
    constructor(ResultId, TableId, NodeId) {
        this.ResultId = ResultId;
        this.TableId = TableId;
        this.NodeId = NodeId;
    }

    get() {
        const key = `${this.ResultId}:${this.TableId}:${this.NodeId}`;
        if (store[key]) return store[key]
        return null;
    }

    set(item) {
        const key = `${this.ResultId}:${this.TableId}:${this.NodeId}`;
        store[key] = item;
    }
}