import { useState, useEffect } from "react";
import { getDataFromPrevNode } from 'src/utils/get-params';

const useConfig = (data = {}, error, isLocal) => {
  const [num, setNum] = useState(3);
  const [config, setConfig] = useState([]);

  useEffect(() => {
    if (!error && data) {
      // data logic
      const dataForm = data.dataForm;
      setNum(dataForm.number || 3);

      setConfig(isLocal ? data.data : getDataFromPrevNode(data));
    }
  }, [data, error]);

  return {
    data: config,
    num,
  };
};

export default useConfig;
