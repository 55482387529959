import React, { useRef } from "react";
import { Empty } from "antd";
import wrapper from "src/modules/wrapper";
import useConfig from "./config";

function BasicInfo({ flow, width, height }) {
  const { data = [] } = useConfig(flow.data, flow.error, flow.isLocal);

  const divRef = useRef(null);
  // 宽度大于360px显示两列
  const show2Column = (divRef.current?.offsetWidth ?? 360) >= 360;

  return (
    <>
      {data.length ? (
        <div ref={divRef} className="max-h-[390px] w-full overflow-y-auto">
          <div
            className={`gap-[18px] text-left leading-[20px] ${show2Column ? "columns-2" : ""
              }`}
          >
            {data.map((item) => (
              <div className="pb-[18px] break-inside-avoid">
                <div className="text-[10px]">{item.title}</div>
                <ul className="flex flex-col gap-[8px] break-inside-avoid">
                  {item.data.map((i) => (
                    <li className="flex justify-between border-b border-b-[#E5E5E5] text-[12px]">
                      <span>{i.title}</span>
                      <span>{i.value}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Empty />
      )}
    </>
  );
}

export default wrapper(BasicInfo);
