import { useState, useEffect } from 'react';
import { Spin } from 'antd';
import useFlow from 'src/hooks/useFlow';
import useLocal from 'src/hooks/useLocal';
import { Layout } from 'src/index';
import Error from 'src/modules/Error';
import { themeColors } from 'src/constants/color'

const wrapper = (Component) => {
  return (props) => {
    // PROPS
    const { ResultId, TableId, NodeId, workflowId, domId, localData } = props;

    // STATES
    const flow = useFlow(ResultId, TableId, NodeId, workflowId);
    const localFlow = useLocal(localData);
    const [themeColor, setThemeColor] = useState(null);
    const [bgColor, setBgColor] = useState('#FFFFFF');
    const [textColor, setTextColor] = useState('#000000');
    const [fontFamily, setFontFamily] = useState('Courier New');
    const [showTitle, setShowTitle] = useState(true);

    // EFFECTS
    useEffect(() => {
      if (localData) {
        localFlow.setCache(localData)
        localFlow.getData(localData);
        return;
      };
      flow.setCache(ResultId, TableId, NodeId);
      if (ResultId && TableId && NodeId) {
        flow.getData(ResultId, TableId, NodeId);
      }
    }, [ResultId, TableId, NodeId, localData])

    useEffect(() => {
      if (flow.error) {
        console.error(flow.error);
      }
    }, [flow.error, localFlow.error])

    // RENDER
    try {
      return (
        <Layout
          domId={domId}
          setThemeColor={setThemeColor}
          setTextColor={setTextColor}
          textColor={textColor}
          bgColor={bgColor}
          setBgColor={setBgColor}
          showTitle={showTitle}
          setShowTitle={setShowTitle}
          fontFamily={fontFamily}
          setFontFamily={setFontFamily}
          {...props}
        >
          <Spin delay={1000} wrapperClassName="wemo-flow-spin" style={{ height: '100%' }} tip="数据加载中" spinning={flow.loading}>
            {
              flow.error ? <Error e={flow.error} /> : <Component
                themeColor={themeColors[themeColor] || []}
                textColor={textColor}
                bgColor={bgColor}
                fontFamily={fontFamily}
                {...props}
                flow={localData ? localFlow : flow}
              />
            }
          </Spin>
        </Layout>
      )
    } catch (e) {
      console.error(e);
      return <Error error={e} />
    }
  }
}

export default wrapper;
