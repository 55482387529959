import { useState, useEffect } from "react";
import { getDataFromPrevNode } from 'src/utils/get-params';
import titleBgImg from "../demoImages/title.png";

const useConfig = (data = {}, error, isLocal) => {
  const [config, setConfig] = useState({
    itemNum: 3,
    data: {
      title: "",
      titleBg: "",
      secondaryTitle: "",
    },
  });

  useEffect(() => {
    if (!error && data) {
      const { itemNum = 3 } = data.dataForm;
      // mock data;
      const title = "Tesla Lithium Refinery Groundbreaking";
      const secondaryTitle =
        "Today, we are breaking ground on Tesla’s in-house lithium refinery, located in the greater...";
      const titleBg = titleBgImg;

      const newData = isLocal ? data.data : getDataFromPrevNode(data);

      setConfig({
        itemNum,
        data: newData,
      });
    }
  }, [data, error]);

  return config;
};

export default useConfig;
