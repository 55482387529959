// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #333;

    .price-unit {
        font-size: 20px;
    }

    .price-data {
        font-size: 50px;
        position: relative;
        top: -7px;
        font-weight: bold;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Price/style.scss"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,WAAW;;IAEX;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;QACf,kBAAkB;QAClB,SAAS;QACT,iBAAiB;IACrB;AACJ","sourcesContent":[".price-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    color: #333;\n\n    .price-unit {\n        font-size: 20px;\n    }\n\n    .price-data {\n        font-size: 50px;\n        position: relative;\n        top: -7px;\n        font-weight: bold;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
