import { useState } from 'react'
import request from 'src/adapters/flow';
import Cache from 'src/modules/Cache';

/**
 * @param {string} ResultId - ResultId
 * @param {string} TableId - TableId by flow runed
 * @param {string} NodeId - id of node
 *
 * @returns {object} hooks return
 */
const useFlow = (ResultId, TableId, NodeId, workflowId) => {
    const [config, setConfig] = useState({ ResultId, TableId, NodeId, workflowId });
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const getData = async (ResultId, TableId, NodeId) => {
        if (loading) {
            return
        }
        setLoading(true);
        try {
            let params = { ResultId, TableId, NodeId };
            if (
                (
                    config.ResultId &&
                    config.TableId &&
                    config.NodeId
                ) || (
                    !ResultId ||
                    !TableId ||
                    !NodeId
                )
            ) params = { ResultId: config.ResultId, TableId: config.TableId, NodeId: config.NodeId }
            const result = await request().post('', {
                type: 'getFlowResult',
                // args: { ResultId, TableId, NodeId },
                args: { ...params },
            })
            if (result.data.errCode === 1) throw new Error(result.data.errMessage)
            const data = JSON.parse(result.data.result)
            setData(data);
            const cache = new Cache(ResultId, TableId, NodeId);
            cache.set(data);
            setError(null);
        } catch (e) {
            setError(e || 'Unkown Error.');
        } finally {
            setLoading(false);
        }
    }

    const clear = () => {
        setData(null);
        setError(null);
        setLoading(false);
    }

    const refresh = async (query) => {
        if (loading) {
            return
        }
        setLoading(true);
        try {
            const result = await request().post('', {
                type: 'runWemoFlow',
                args: { workflowId: config.workflowId, workflowTableId: null, query }
            })
            const { workflowTableId } = result.data;
            if (result.data.errCode === 1) throw new Error(result.data.errMessage)
            seatchWemoFLowResult(workflowTableId, config.NodeId);
        } catch (e) {
            setError(e);
            setLoading(false);
        }
    }

    const seatchWemoFLowResult = async (tableId, NodeId) => {
        try {
            const result = await request().post('', {
                type: 'getWemoFlowState',
                args: { tableId, workflowId }
            })
            const table = JSON.parse(result.data.table || '[]');
            const current = table.find(item => item.NodeId === NodeId);
            if (!current) {
                setTimeout(() => seatchWemoFLowResult(tableId, NodeId), 1000);
            } else {
                setLoading(false);
                const { ResultId } = current;
                setConfig({ ResultId, NodeId, TableId: tableId, workflowId: config.workflowId })
                setTimeout(getData, 0);
            }
        } catch (e) {
            setError(e);
            setLoading(false);
        }
    }

    const setCache = () => {
        const cache = new Cache(ResultId, TableId, NodeId);
        const data = cache.get();
        setData(data)
    }

    return {
        data, // 数据
        loading, // loading的状态
        error, // error
        clear, // 清空数据的方法
        getData, // 发起请求
        refresh, // 重新发起请求
        setCache,
    }
}

export default useFlow;