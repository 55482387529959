import { Empty } from 'antd';
import error from 'src/assets/error.png';

import "./style.scss";

const Error = ({ e }) => {
    return (
        <div className='wemo-flow-error'>
            <Empty
                description={e ? e.message : "检测到未知错误"}
                image={error}
            />
        </div>
    )
}

export default Error;