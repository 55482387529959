import PropTypes from "prop-types";
import { Empty } from "antd";
import useConfig from "./config";
import wrapper from "src/modules/wrapper";
import BaseDualAxes from "../BaseDualAxes";

const OneLineTwoColumnChart = ({ flow, width, height }) => {
  const config = useConfig(flow.data, flow.error, flow.isLocal);

  return (
    <>
      {config.columnData.length || config.lineData.length ? (
        <BaseDualAxes
          {...config}
          columnSeriesFieldNum={2}
          lineSeriesFieldNum={1}
        />
      ) : (
        <Empty />
      )}
    </>
  );
};

OneLineTwoColumnChart.propTypes = {
  flow: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default wrapper(OneLineTwoColumnChart);
