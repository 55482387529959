import axios from 'axios'

function getHandle(host, isHttp) {
    return (path, data, headers) => {
        const params = {
            method: 'get',
            url: `${host}${path}`,
        }
        if (data) {
            params.params = data;
        }
        if (headers) {
            params.headers = headers;
        }
        try {
            const req = axios(params);
            return req;
        } catch (e) {
            throw new Error(e);
        }
    }
}

function postHandle(host, isHttp) {
    return (path, data, headers) => {
        const params = {
            method: 'post',
            url: `${host}${path}`,
        }
        if (data) {
            params.data = data;
        }
        params.headers = {}
        if (headers) {
            params.headers = {
                ...params.headers,
                ...headers,
            }
        }
        try {
            const req = axios(params);
            return req;
        } catch (e) {
            throw new Error(e);
        }
    }
}

export default function request(host, useHttp) {
    return {
        get: getHandle(host, useHttp),
        post: postHandle(host, useHttp),
    }
}
