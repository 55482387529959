import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/loading/Loadable'

// chatflows routing
const Chatflows = Loadable(lazy(() => import('views/chatflows')))

// marketplaces routing
const Marketplaces = Loadable(lazy(() => import('views/marketplaces')))

// apikey routing
const APIKey = Loadable(lazy(() => import('views/apikey')))

// apikey routing
const Tools = Loadable(lazy(() => import('views/tools')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        // {
        //     path: '/',
        //     element: <Chatflows />
        // },
        // {
        //     path: '/chatflows',
        //     element: <Chatflows />
        // },
        // {
        //     path: '/marketplaces',
        //     element: <Marketplaces />
        // },
        // {
        //     path: '/apikey',
        //     element: <APIKey />
        // },
        // {
        //     path: '/tools',
        //     element: <Tools />
        // }
    ]
}

export default MainRoutes
