import { useState, useEffect } from "react";
import titleBgImg from "../demoImages/title.png";
import { getDataFromPrevNode } from 'src/utils/get-params';

const useConfig = (data = {}, error, isLocal) => {
  const [config, setConfig] = useState({
    itemNum: 3,
    data: {
      title: "",
      titleBg: "",
      itemMap: [],
    },
  });

  useEffect(() => {
    if (!error && data) {
      const { itemNum = 3 } = data.dataForm;
      // mock data;
      const title = "Tesla Lithium Refinery Groundbreaking";
      const titleBg = titleBgImg;
      // const itemMap = [
      //   {
      //     itemTitle: "Tesla Lithium Refinery Groundbreaking",
      //     itemContent:
      //       "Today, we are breaking ground on Tesla’s in-house lithium refinery, located in the greater Corpus Christi area of Texas. Once",
      //   },
      //   {
      //     itemTitle: "Master Plan Part 3",
      //     itemContent:
      //       "Today, we are publishing Master Plan Part 3, which outlines a proposed path to reach a sustainable energy economy through",
      //   },
      //   {
      //     itemTitle: "In Response to False Allegations",
      //     itemContent:
      //       "Today, we are breaking ground on Tesla’s in-house lithium refinery, located in the greater Corpus Christi area of Texas. Once",
      //   },
      // ];

      const itemMap = isLocal ? data.data : getDataFromPrevNode(data);

      setConfig({
        itemNum,
        data: {
          title,
          titleBg,
          itemMap,
        },
      });
    }
  }, [data, error]);

  return config;
};

export default useConfig;
