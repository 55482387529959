import { useState, useEffect } from 'react';
import { getDataFromPrevNode } from 'src/utils/get-params';

const useConfig = (data, error, isLocal) => {
  const [config, setConfig] = useState({
    data: [],
    xField: 'x',
    yField: 'y',
    point: {
      size: 5,
      shape: 'circle',
    },
  });

  useEffect(() => {
    if (!error && data) {
      const dataForm = data.dataForm;
      const newData = isLocal ? data.data : getDataFromPrevNode(data);
      setConfig({
        data: newData || [],
        xField: dataForm.xField || '',
        yField: dataForm.yField || '',
        point: {
          size: 5,
          shape: 'circle',
        },
      });
    }
  }, [data, error]);

  return {
    data: config,
  };
};

export default useConfig;
