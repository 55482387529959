import { useState, useEffect } from 'react';
import { getDataFromPrevNode } from 'src/utils/get-params';

const useConfig = (data, error, isLocal) => {
  const [config, setConfig] = useState({
    min: 0,
    max: 0,
    value: 0,
  });

  useEffect(() => {
    if (!error && data) {
      const dataForm = data.dataForm;
      const newData = isLocal ? data.data : getDataFromPrevNode(data);
      const min = newData[dataForm.min];
      const max = newData[dataForm.max];
      const percent = newData[dataForm.value] / max;
      setConfig({
        dataInitialization: true,
        min,
        max,
        percent,
      });
    }
  }, [data, error]);

  return {
    data: config,
  };
};

export default useConfig;
