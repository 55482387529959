import React, { useMemo, useState, useEffect } from 'react';
import { Collapse, Space, Spin } from 'antd';
// import 'antd/dist/antd.css'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import cx from 'classnames';
import useAI from 'src/hooks/useAI';
import Error from 'src/modules/Error';
import wrapper from 'src/modules/wrapper';
import { ReactComponent as ArrowSvg } from 'src/assets/arrow.svg';
import './style.scss';
import useConfig from './config.js';
import Stock from './components/stock';
import request, { API_KEY } from 'src/adapters/fixed';
import useCountDown from 'src/hooks/use-countdown';
import voiceIcon from 'src/assets/answer.gif';

dayjs.extend(utc);

const promptGen = (data, aiAgentName) => `
你是一个${aiAgentName}的分析师，以下数据是行业中其他企业的实时股票数据:
${JSON.stringify(data)}
请用专业的语言，先用一段文字总结你获得的数据，再分析这些数据对${aiAgentName}的潜在影响。
请用英文回答我
`

function CompetitiveAnalysis({ flow, company, aiAgentName, width, height, defaultOpen }) {
  const { countTime, startCount, showCount } = useCountDown();
  const config = useConfig(flow.data, flow.error, flow.isLocal);
  const [data, setData] = useState({});
  const [collapse, setCollapse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [realLoading, setRealLoading] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const ai = useAI()

  async function _fetchData() {
    setLoading(true);
    const companyProfiles = await Promise.all(
      company.map((it) =>
        request.get(`/v3/profile/${it.symbol}`, {
          apikey: API_KEY,
        })
      )
    );
    setLoading(false);
    setData({
      companyProfiles: companyProfiles.map((it) => it.data[0]),
      error: companyProfiles.some(it => it.data[0] === null || it.data[0] === undefined),
    });
  }

  async function _fetchRealTimeData(init) {
    setRealLoading(true);
    const cacheQuotes = await Promise.all(
      company.map((it) =>
        request.get(`/v3/quote/${it.symbol}`, {
          apikey: API_KEY,
        })
      )
    );
    init && _fetchData();
    startCount(60);
    setTimeout(() => {
      setRealLoading(false);
    }, 600);
    const arr = cacheQuotes.map((it) => it.data[0]);
    setQuotes(arr);
  }

  useEffect(() => {
    if (data && data.companyProfiles && data.companyProfiles.length && !data.error) {
      const prompt = promptGen(data.companyProfiles, aiAgentName);
      ai.chat(prompt)
      const openKey = data.companyProfiles.map(item => item.cik)
      setCollapse(openKey)
    }
  }, [data])

  useEffect(() => {
    if (data && data.companyProfiles && data.companyProfiles.length && !data.error && refresh) {
      setRefresh(false)
      const prompt = promptGen(data.companyProfiles, aiAgentName);
      ai.chat(prompt)
      const openKey = data.companyProfiles.map(item => item.cik)
      setCollapse(openKey)
    }
  }, [refresh, data])

  useEffect(() => {
    _fetchRealTimeData(true);
  }, []);
  useEffect(() => {
    if (countTime === 1) {
      _fetchRealTimeData();
    }
  }, [countTime]);

  return (
    <div className="competitive-analysis-wrapper">
      <div className="competitive-analysis-header">
        <img src={voiceIcon} alt="" className="icon" />
        <span className="title">AI STOCK ANALYSIS</span>
      </div>
      <Spin
        delay={1000}
        wrapperClassName="competitive-analysis-list-box"
        tip="AI is summarizing..."
        spinning={ai.loading}
      >
        {ai.error ?
          <div className="ai-content-refresh" onClick={() => setRefresh(true)}>
            Something went wrong, please try again...
          </div> :
          <div className="ai-content">
            {ai.answer}
          </div>}
      </Spin>
      <div className="line-box">
        <span className="text">Citation</span>
        <span className="line"></span>
      </div>
      <Spin
        delay={1000}
        wrapperClassName="competitive-analysis-list-box"
        tip="loading..."
        spinning={loading}
      >
        {data.error ? (
          <Error />
        ) : (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Collapse
              collapsible="header"
              expandIconPosition="end"
              expandIcon={() => <></>}
              ghost
              className="site-collapse-custom-collapse"
              onChange={(key) => setCollapse(key)}
              defaultActiveKey={collapse}
              activeKey={collapse}
            >
              {(data?.companyProfiles || []).map((it, index) => (
                <Collapse.Panel
                  header={
                    collapse.indexOf(it.cik) > -1 ? (
                      <div className="custom-active-panel-header">
                        <span className="company-info">
                          {it.companyName}({it.symbol})
                        </span>
                        <span className="tips">
                          {quotes[index].exchange} - {quotes[index].exchange} Real Time Price. Currency in USD
                        </span>
                      </div>
                    ) : (
                      <div
                        className={cx(
                          'custom-panel-header',
                          realLoading && 'gray'
                        )}
                      >
                        <span
                          className={cx(
                            'trend',
                            quotes[index].change >= 0 ? 'up' : 'down'
                          )}
                        >
                          <ArrowSvg />
                        </span>
                        <div className="company-info">
                          <span>
                            {it.companyName}({it.symbol})
                          </span>
                          <span className="price">${quotes[index].price}</span>
                        </div>
                      </div>
                    )
                  }
                  key={it.cik}
                  className="site-collapse-custom-panel"
                  forceRender={true}
                >
                  <Stock
                    realLoading={realLoading}
                    stockInfo={quotes[index]}
                    company={{ symbol: it.symbol, cik: it.cik }}
                  />
                </Collapse.Panel>
              ))}
            </Collapse>
          </Space>
        )}
      </Spin>
    </div>
  );
}

export default wrapper(CompetitiveAnalysis);
