// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting-model-container {
    padding: 16px;
}

.setting-model-item {
    width: 220px;
    color: #738098;
    margin-bottom: 16px;

    .setting-model-item-title {
        margin-bottom: 7px;
    }
}

.ant-select-selection-item {
    display: flex;
}

.ant-select-item-option-content {
    display: flex;
}

.color-picker-overview-layout {
    width: 100%;
    border: 2px solid #2C3444;
    height: 32px;
    border-radius: 1000px;
    padding: 1px;
    display: flex;
    align-items: center;
    padding-left: 12px;

    .color-picker-overview {
        width: 80px;
        height: 16px;
    }
}

.setting-model-title-edit {
    display: flex;
    align-items: center;

    &>span {
        flex: 1;
    }
}

.ant-switch-checked {
    background-color: #000 !important;
}`, "",{"version":3,"sources":["webpack://./src/modules/Setting/style.scss"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,mBAAmB;;IAEnB;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;IACrB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;;IAElB;QACI,WAAW;QACX,YAAY;IAChB;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB;QACI,OAAO;IACX;AACJ;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".setting-model-container {\n    padding: 16px;\n}\n\n.setting-model-item {\n    width: 220px;\n    color: #738098;\n    margin-bottom: 16px;\n\n    .setting-model-item-title {\n        margin-bottom: 7px;\n    }\n}\n\n.ant-select-selection-item {\n    display: flex;\n}\n\n.ant-select-item-option-content {\n    display: flex;\n}\n\n.color-picker-overview-layout {\n    width: 100%;\n    border: 2px solid #2C3444;\n    height: 32px;\n    border-radius: 1000px;\n    padding: 1px;\n    display: flex;\n    align-items: center;\n    padding-left: 12px;\n\n    .color-picker-overview {\n        width: 80px;\n        height: 16px;\n    }\n}\n\n.setting-model-title-edit {\n    display: flex;\n    align-items: center;\n\n    &>span {\n        flex: 1;\n    }\n}\n\n.ant-switch-checked {\n    background-color: #000 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
